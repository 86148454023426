import React, { useState, useEffect } from "react";
import { classNames } from "primereact/utils";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import { Tag } from "primereact/tag";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import { CustomerService } from "./service/CustomerService";
import { InputSwitch } from "primereact/inputswitch";
import { Calendar } from 'primereact/calendar';
import { database } from '../firebaseConfig';
import { ref, get, child } from "firebase/database";




export default function BasicFilterDemo() {
  const [customers, setCustomers] = useState(null);
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    priority: { value: null, matchMode: FilterMatchMode.EQUALS },
    city: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ciHolder: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ciPatient: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    namePatient: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    id: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }] },
  });
  const [loading, setLoading] = useState(true);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [representatives] = useState([
    { name: "Amy Elsner", image: "amyelsner.png" },
    { name: "Anna Fali", image: "annafali.png" },
    { name: "Asiya Javayant", image: "asiyajavayant.png" },
    { name: "Bernardo Dominic", image: "bernardodominic.png" },
    { name: "Elwin Sharvill", image: "elwinsharvill.png" },
    { name: "Ioni Bowcher", image: "ionibowcher.png" },
    { name: "Ivan Magalhaes", image: "ivanmagalhaes.png" },
    { name: "Onyama Limba", image: "onyamalimba.png" },
    { name: "Stephen Shaw", image: "stephenshaw.png" },
    { name: "XuXue Feng", image: "xuxuefeng.png" },
  ]);
  const [statuses] = useState([
    "unqualified",
    "remesado",
    "atendido",
    "pendiente",
    "renewal",
  ]);

  const [selectedProducts, setSelectedProducts] = useState(null);

  const getSeverity = (status) => {
    switch (status) {
      case "unqualified":
        return "danger";

      case "atendido":
        return "success";

      case "remesado":
        return "info";

      case "pendiente":
        return "warning";

      case "renewal":
        return null;
    }
  };

  useEffect(() => {
    // CustomerService.getCustomersMedium().then((data) => {
    //   setCustomers(getCustomers(data));
    //   setLoading(false);
    // });

    const fetchCases = async () => {
      const dbRef = ref(database);
      try {
        const snapshot = await get(child(dbRef, 'casos/'));
        if (snapshot.exists()) {
          const data = snapshot.val();
          console.log(data)
          const casesList = Object.keys(data).map(key => ({
            id: key,
            ...data[key]
          }));
          console.log(casesList);
          setCustomers(casesList);
          setLoading(false);
        } else {
          console.log("No data available");
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchCases();
    console.log(customers);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCustomers = (data) => {
    return [...(data || [])].map((d) => {
      d.date = new Date(d.date);

      return d;
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Keyword Search"
          />
        </IconField>
      </div>
    );
  };

  const countryBodyTemplate = (rowData) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt="flag"
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`flag flag-${rowData.country.code}`}
          style={{ width: "24px" }}
        />
        <span>{rowData.country.name}</span>
      </div>
    );
  };

  const representativeBodyTemplate = (rowData) => {
    const representative = rowData.representative;

    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={representative.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${representative.image}`}
          width="32"
        />
        <span>{representative.name}</span>
      </div>
    );
  };

  const representativesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <img
          alt={option.name}
          src={`https://primefaces.org/cdn/primereact/images/avatar/${option.image}`}
          width="32"
        />
        <span>{option.name}</span>
      </div>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <Tag value={rowData.status} severity={getSeverity(rowData.status)} />
    );
  };

  const statusItemTemplate = (option) => {
    return <Tag value={option} severity={getSeverity(option)} />;
  };

  const verifiedBodyTemplate = (rowData) => {
    // ( <i className={classNames('pi', { 'text-green-500 pi-check-circle': rowData.verified, 'text-red-500 pi-times-circle': !rowData.verified })}></i>;)
    return (
      <i
        className={classNames("pi", {
          "text-green-500 true-icon pi-check-circle": rowData.priority,
          "text-red-500 false-icon pi-times-circle": !rowData.priority,
        })}
      ></i>
    );
  };

  const representativeRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={representatives}
        itemTemplate={representativesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        optionLabel="name"
        placeholder="Any"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };

  const statusRowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={statuses}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={statusItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };

  const verifiedRowFilterTemplate = (options) => {
    return (
      <TriStateCheckbox
        value={options.value}
        onChange={(e) => options.filterApplyCallback(e.value)}
      />
    );
  };
  const dateFilterTemplate = (options) => {
    return (
      <Calendar
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        dateFormat="mm/dd/yy"
        placeholder="mm/dd/yyyy"
        mask="99/99/9999"
      />
    );
  };
  const dateBodyTemplate = (rowData) => {
    return formatDate(rowData.date);
  };

  const formatDate = (value) => {
    return value.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };
  

  const header = renderHeader();

  return (
    <div className="card" style={{}}>

      <div  class="p-2">
        <DataTable
          value={customers}
          paginator
          rows={10}
          dataKey="id"
          filters={filters}
          filterDisplay="row"
          loading={loading}
          globalFilterFields={[
            "name",
            "country.name",
            "representative.name",
            "status",
          ]}
          header={header}
          emptyMessage="No customers found."
          selectionMode={null}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          stripedRows
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>

          <Column
            field="id"
            header="ID"
            filter
            filterPlaceholder="Buscar por ID"
          ></Column>
          <Column
            field="ciHolder"
            header="CI Titular"
            filter
            filterPlaceholder="Buscar por Titular"
          ></Column>
          <Column
            field="ciPatient"
            header="CI Paciente"
            filter
            filterPlaceholder="Buscar por CI Paciente"
          ></Column>
          <Column
            field="namePatient"
            header="Nombre Paciente"
            filter
            filterPlaceholder="Buscar por Nombre"
          ></Column>
          <Column field="filial" header="Filial" filter filterPlaceholder="Buscar por Filial"></Column>
          <Column field="phone" header="Teléfono" filter filterPlaceholder="Buscar por Teléfono"></Column>
          <Column field="birthdate" header="Fecha de Nacimiento" filter filterPlaceholder="Buscar por Fecha"></Column>
          <Column field="sex" header="Sexo" filter filterPlaceholder="Buscar por Fecha"></Column>
          <Column field="requirement" header="Requerimiento" filter filterPlaceholder="Buscar por requerimiento"></Column>
          <Column field="requirementTipe" header="Tipo de requerimiento" filter filterPlaceholder="Buscar por tipo de requerimiento"></Column>
          <Column field="diagnostic" header="Diagnóstico" filter filterPlaceholder="Buscar por Fecha"></Column>

          <Column field="city" header="Ciudad" filter filterPlaceholder="Buscar por Ciudad"></Column>
          <Column
            field="status"
            header="Status"
            showFilterMenu={false}
            filterMenuStyle={{ width: "14rem" }}
            style={{ minWidth: "12rem" }}
            body={statusBodyTemplate}
            filter
            filterElement={statusRowFilterTemplate}
          />
          {/* <Column
            field="date"
            header="Date"
            filter
            dataType="date"
            filterField="date" 
            filterElement={dateFilterTemplate}
            filterPlaceholder="Buscar por fecha"
            style={{ minWidth: "12rem" }}
            body={dateBodyTemplate}
          /> */}
          <Column
            field="priority"
            header="Priorizado"
            dataType="boolean"
            style={{ minWidth: "6rem" }}
            body={verifiedBodyTemplate}
            filter
            filterElement={verifiedRowFilterTemplate}
          />
          {/* <Column field="name" header="Name" filter filterPlaceholder="Search by name" style={{ minWidth: '12rem' }} /> */}
          {/* <Column header="Country" filterField="country.name" style={{ minWidth: '12rem' }} body={countryBodyTemplate} filter filterPlaceholder="Search by country" /> */}
          {/* <Column header="Agent" filterField="representative" showFilterMenu={false} filterMenuStyle={{ width: '14rem' }} style={{ minWidth: '14rem' }} */}
          {/* body={representativeBodyTemplate} filter filterElement={representativeRowFilterTemplate} /> */}
          {/* <Column field="verified" header="Verified" dataType="boolean" style={{ minWidth: '6rem' }} body={verifiedBodyTemplate} filter filterElement={verifiedRowFilterTemplate} /> */}
        </DataTable>
      </div>
    </div>
  );
}
