import React, { Children } from "react";
import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";
import { Panel } from "primereact/panel";
import { Button } from "primereact/button";
import "./Dashboard.css";
import TopBar from "../TopBar/TopBar";
import { Menu } from "primereact/menu";

export default function Dashboard({children}) {
  let items = [
    { label: "Crear caso", icon: "pi pi-plus", url: "/caso" },
    {
      label: "Listado de casos",
      icon: "pi pi-list-check",
      url: "/",
      // command: () => {
      //   // router.push("/installation");
      // },
    },
  ];
  return (
    <div className="layout-dashboard">
      <TopBar></TopBar>
      <div className="layout-content">
        <div className="layout-sidebar">
          <div class="mb-4">
            <Avatar label="LM" size="xlarge" shape="circle" />
            <Badge value="8" severity="info" />

          </div>

          <Menu model={items} />
        </div>
        <div className="layout-main-container">
          <div className="layout-main">
            <Panel header="Dashboard">
              
              {children}
            </Panel>

          </div>
        </div>
      </div>
    </div>
  );
}
