import React from "react";
import BasicFilterDemo from "./table/BasicFilterDemo";
import Dashboard from "./Layout/Dashboard";

class Table extends React.Component {
  render() {
    return (
      <Dashboard>
        <BasicFilterDemo></BasicFilterDemo>
      </Dashboard>
    );
  }
}

export default Table;
