import React from 'react';
import Dashboard from "./Layout/Dashboard";
import CreateCase from './CreateCase/CreateCase';

function MyComponent() {
    return (
        <Dashboard>
            <CreateCase></CreateCase>
        </Dashboard>        
    );
}

export default MyComponent;