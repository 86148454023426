// FormComponent.js
import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { database } from "../firebaseConfig";
import { ref, set, get, child, push } from "firebase/database";

// {
//     id: 1005,
//     ciHolder: '14552454',
//     ciPatient: '25885852',
//     namePatient: 'Simona Morasca',
//     city: 'Puerto la cruz',
//     filial: 'PDVSA',
//     status: 'pendiente',
//     date: '2016-09-13',
//     priority: false,
// },

// Escribir datos
function writeCaseData(ciHolder, ciPatient, filial) {
    const newCaseRef = push(ref(database, "casos/"));
    set(newCaseRef, {
        ciHolder: ciHolder,
        ciPatient: ciPatient,
        filial: filial,
      });
}

const FormComponent = () => {
  const [ciHolder, setCiHolder] = useState("");
  const [ciPaciente, setCiPatient] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [gender, setGender] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);
  const [phone, setPhone] = useState(null);
  const [birthdate, setBirthdate] = useState(null);

  const genders = [
    { label: "Masculino", value: "male" },
    { label: "Femenino", value: "female" },
  ];

  const phoneCodes = [
    { label: "0414", value: "414" },
    { label: "0424", value: "424" },
    { label: "0416", value: "416" },
    { label: "0426", value: "426" },
    { label: "0412", value: "412" },
  ]


  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log({ name, email, gender, dob });
    writeCaseData(ciHolder, ciPaciente, "PDVSA");
  };

  return (
    <div className="p-fluid">
      <form onSubmit={handleSubmit}>
        <div className="p-field">
          <label htmlFor="name">Ci titular</label>
          <InputText
            id="name"
            value={ciHolder}
            onChange={(e) => setCiHolder(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="name">Ci Paciente</label>
          <InputText
            id="name"
            value={ciPaciente}
            onChange={(e) => setCiPatient(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="name">Nombre completo</label>
          <InputText
            id="email"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="p-field">
          <label htmlFor="gender">Gender</label>
          <Dropdown
            id="gender"
            value={gender}
            options={genders}
            onChange={(e) => setGender(e.value)}
            placeholder="Select a Gender"
          />
        </div>
        <div className="p-field">
          <label htmlFor="gender">Gender</label>
          <Dropdown
            id="gender"
            value={phoneCode}
            options={phoneCodes}
            onChange={(e) => setPhoneCode(e.value)}
            placeholder="phonce code"
          />
        </div>
        <div className="p-field">
          <label htmlFor="dob">Fecha de nacimiento</label>
          <Calendar
            id="dob"
            value={birthdate}
            onChange={(e) => setBirthdate(e.value)}
            showIcon
          />
        </div>
        <Button type="submit" label="Submit" className="mt-4" />
      </form>
    </div>
  );
};

export default FormComponent;
