// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCpzDgUs4CO_UdSU1lRqsAR2swZRP3nBCY",
  authDomain: "citamed247.firebaseapp.com",
  projectId: "citamed247",
  storageBucket: "citamed247.appspot.com",
  messagingSenderId: "952372042158",
  appId: "1:952372042158:web:67877ccfeaf75d2ef2903f",
  measurementId: "G-5K8ZN9WC6F",
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const database = getDatabase(app);

export { database, analytics };
