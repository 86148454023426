import "./App.css";
import { PrimeReactProvider } from "primereact/api";
import React from "react";
import BasicFilterDemo from "./table/BasicFilterDemo";
import TopBar from "./TopBar/TopBar";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import Dashboard from "./Layout/Dashboard";
import Case from "./Case";
import { BrowserRouter as Router, Route , RouterProvider, createBrowserRouter} from 'react-router-dom';
import Table from './Table'; // Asegúrate de tener este componente
// import AnotherComponent from './AnotherComponent'; // Asegúrate de tener este componente


const router = createBrowserRouter([
  {
    path: "/",
    element: <Table/>,
  },
  {
    path: "/caso",
    element: <Case/>,
  },
]);




function App() {
  return (
    <PrimeReactProvider>
      <RouterProvider router={router} />
    </PrimeReactProvider>
  );
}

export default App;

// function App() {
//   return (
//     <PrimeReactProvider>
//       {/* <Component {...pageProps} /> */}
//       {/* <TopBar></TopBar> */}
//       <Dashboard>
//         <BasicFilterDemo></BasicFilterDemo>
//       </Dashboard>
//     </PrimeReactProvider>
//   );
// }