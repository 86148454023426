import React from "react";
import { Menubar } from "primereact/menubar";
import { InputText } from "primereact/inputtext";
import { Badge } from "primereact/badge";
import { Avatar } from "primereact/avatar";

export default function TemplateDemo() {
  const itemRenderer = (item) => (
    <a className="flex align-items-center p-menuitem-link">
      <span className={item.icon} />
      <span className="mx-2">{item.label}</span>
      {item.badge && <Badge className="ml-auto" value={item.badge} />}
      {item.shortcut && (
        <span className="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">
          {item.shortcut}
        </span>
      )}
    </a>
  );
  const items = [
    {
      label: "Home",
      icon: "pi pi-home",
    },
  ];

  const start = (null);
  const end = (
    <img
      alt="logo"
      src="https://24siete-storage.s3.amazonaws.com/wp-content/uploads/2024/05/08092915/logo-web-2-1.png"
      height="40"
      className="mr-2"
    ></img>
    // <div className="flex align-items-center gap-2">
    //   <Avatar
    //     style={{width: "11rem", paddingRight: "1rem"}}
    //     size="large"
    //     image="https://24siete-storage.s3.amazonaws.com/wp-content/uploads/2024/05/08092915/logo-web-2-1.png"

    //   />
    // </div>
  );

  return (
    <div className="card">
      <Menubar model={items} start={start} end={end} />
    </div>
  );
}
